const startWork = (button) => {
	button && button.classList.add('loading');

	jQuery.post(online_media.ajaxUrl, {
		action: 'online_media_start_work', nonce: online_media.nonce,
	}).done(data => {
		if (data.data.success) {
			window.location.href = data.data.redirect;
		}
	}).fail(err => console.error(err));

	button.classList.remove('loading');
};

export {
	startWork
}
