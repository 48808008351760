/**
 * Showing element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}             HTML Element from Document
 * @param InputToFocus {Element|String} Pass HTML element or selector
 * @param elClass {string}              Class to control, default is show
 */
function ShowElement(element, InputToFocus = '', elClass = 'show') {
	element.setAttribute('aria-expanded', 'true');
	// Get element to open from btn's data-open attribute
	const target_element = document.querySelector(element.dataset.open),
		type_of_animation = element.dataset.hasOwnProperty('function') ? element.dataset.function : null;

	if (target_element == undefined) {
		console.warn('Cannot find target element: ', target_element);
		return;
	}

	// If element to open has class modal, add class overflow to body
	// Overflow class is used in css to apply overflow hidden on body
	// to prevent scrolling while modal is openned
	if (target_element.classList.contains('modal') || target_element.classList.contains('navbar-collapse')) {
		document.body.classList.add('overflow');
	}
	switch (type_of_animation) {
		case 'fade':
			$(target_element).fadeIn();
			break;
		case 'slide':
			$(target_element).slideDown();
			break;
		default:
			target_element.classList.add(elClass);
	}

	// Focus on input after openning modal if provided
	if (InputToFocus) {
		const input_el = InputToFocus instanceof HTMLElement ? InputToFocus : target_element.querySelector(InputToFocus);
		input_el && input_el.focus();
	}
	window.toggled_btn && window.toggled_btn.setAttribute('aria-expanded', 'false');
	window.toggled_btn = element;
	// window.showed_item && window.showed_item.classList.remove( elClass );
	// window.showed_item = target_element;
	new Emmit(target_element, { type: 'show' });
}

/**
 * Hiding element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}     HTML Element from Document
 * @param elClass {string}      Class to control, default is show
 */
function HideElement(element, elClass = 'show') {
	element.setAttribute('aria-expanded', 'false');
	// Get element to open from btn's data-open attribute
	const target_element = element.classList.contains('modal') ? element : document.querySelector(element.dataset.close),
		type_of_animation = element.dataset.hasOwnProperty('function') ? element.dataset.function : null;

	if (target_element == undefined) {
		console.warn('Cannot find target element: ', target_element);
		return;
	}

	// If element to open has class modal, add class overflow to body
	// Overflow class is used in css to apply overflow hidden on body
	// to prevent scrolling while modal is openned
	if (target_element.classList.contains('modal') || target_element.classList.contains('navbar-collapse')) {
		document.body.classList.remove('overflow');
	}
	switch (type_of_animation) {
		case 'fade':
			$(target_element).fadeOut();
			break;
		case 'slide':
			$(target_element).slideUp();
			break;
		default:
			target_element.classList.remove(elClass);
	}
	window.toggled_btn && window.toggled_btn.setAttribute('aria-expanded', 'false');
	window.toggled_btn = undefined;
	// window.showed_item && window.showed_item.classList.remove( elClass );
	// window.showed_item = undefined;
	new Emmit(target_element, { type: 'hide' });
}

/**
 * Toggles element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}     HTML Element from Document
 * @param elClass {string}      Class to control, default is show
 */
function ToggleElement(element, elClass = 'show') {
	element.setAttribute('aria-expanded', element.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
	element.classList.toggle('active');
	// Get element to open from btn's data-open attribute
	const target_element = document.querySelector(element.dataset.togle),
		type_of_animation = element.dataset.hasOwnProperty('function') ? element.dataset.function : null;

	if (target_element == undefined) {
		console.warn('Cannot find target element: ', target_element);
		return;
	}

	// If element to open has class modal, add class overflow to body
	// Overflow class is used in css to apply overflow hidden on body
	// to prevent scrolling while modal is openned
	if (target_element.classList.contains('modal') || target_element.classList.contains('navbar-collapse')) {
		document.body.classList.toggle('overflow');
	}
	switch (type_of_animation) {
		case 'fade':
			$(target_element).fadeToggle();
			break;
		case 'slide':
			$(target_element).slideToggle();
			break;
		default:
			target_element.classList.toggle(elClass);
	}

	window.toggled_btn && window.toggled_btn.toggleAttribute('aria-expanded');
	window.toggled_btn = window.toggled_btn ? undefined : element;
	// window.showed_item && window.showed_item.classList.toggle( elClass );
	// window.showed_item = window.showed_item ? undefined : target_element;
	new Emmit(target_element, { type: 'toggle' });
}

/**
 * Emits custom event
 *
 * @param element {Document, Element, string} - default document
 * @param type
 * @param bubbles
 * @param cancelable
 * @constructor
 */
function Emmit(element, { type, bubbles = true, cancelable = false }) {
	const event = document.createEvent('HTMLEvents'),
		el = element === '' ? document : element instanceof Element ? element : document.querySelector(element);
	if (!el) {
		console.warn('Cannot find element', element);
		return;
	}
	event.initEvent(type, bubbles, cancelable);
	el.dispatchEvent(event);
}

function downloadFile(url) {
	const anchor = document.createElement("a");
	anchor.href = url;
	anchor.download = url.split('/').pop();
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
}

export {
	ShowElement,
	HideElement,
	ToggleElement,
	Emmit,
	downloadFile
};
