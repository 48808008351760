const downloadReport = (form) => {
	const from = form?.querySelector('[name="from"]'),
		to = form?.querySelector('[name="to"]'),
		type = form?.querySelector('[name="type"]'),
		button = form?.querySelector('button');

	const data = {
		action: 'online_media_get_stats', 
		nonce: online_media.nonce,
		from: from?.value,
		type: type?.value,
		to: to?.value,
	};

	button && button.classList.add('loading');

	jQuery.post(online_media.ajaxUrl, data)
		.done(data => {
			if (data.data.status) {
				window.open(data.data.file_url, '_blank');
				from.value = '';
				to.value = '';
			} else {
				alert(data.data.message);
			}
		})
		.fail(err => console.error(err));
		button && button.classList.remove('loading');
};

export {
	downloadReport
}
