import {calculation} from "@modules/calculateDays";
import {calculatePrice} from "@modules/calculatePrice";

const completeNote = (button, note, comment, user, form, regTag, loyalty = 0, extraFee = 0) => {
	button && button.classList.add('loading');

	const dateFrom = form?.querySelector('#date-from'),
		dateTo = form?.querySelector('#date-to'),
		timeFrom = form?.querySelector('#time'),
		timeTo = form?.querySelector('#time_to'),
		payment = button?.value;

	let days = calculation(dateFrom, timeFrom, dateTo, timeTo);

	calculatePrice(days, form?.querySelector('#price input'));

	const price = form?.querySelector('#price input');

	jQuery(document.body).trigger('om_parking_update_schedule', [button, note, comment, form, dateFrom.value, dateTo.value, loyalty, extraFee, days, price.value]);

	let postId = form?.querySelector('input[name="post_id"]')?.value,
	parentPost = form?.querySelector('input[name="parent_post_id"]')?.value;

	const data = {
		action: 'online_media_complete_note',
		post_id: postId,
		note: note,
		comment: comment,
		user: user,
		loyalty: loyalty,
		extra_fee: extraFee,
		nonce: online_media.nonce,
		date_to: dateTo.value,
		date_from: dateFrom.value,
		days: days,
		price: price.value,
		payment: payment,
		reg_tag: regTag
	}

	jQuery.post(online_media.ajaxUrl, data).done(data => {
		setTimeout(() => {
			window.location.reload();
		}, 1000);
		button && button.classList.remove('loading');
	}).fail(err => console.error(err));
}

const addSchedule = (form, url, formBtn) => {
	const data = new FormData(form);

	formBtn?.classList?.add('loading');
	formBtn?.setAttribute('disabled', 'disabled');

	const value = Object.fromEntries(data.entries());

	Object.assign(value, {
		action: 'online_media_create_new_schedule', nonce: online_media.nonce,
	});

	jQuery.post(online_media.ajaxUrl, value).fail(err => console.error(err)).complete(() => {
		setTimeout(() => {
			window.location.reload();
			formBtn?.classList?.remove('loading');
			formBtn?.removeAttribute('disabled');
		}, 1000);
	});
}

export {
	completeNote,
	addSchedule
}
