import {calculatePrice} from "@modules/calculatePrice";

const calculateDays = (dateStartEl, timeStartEl, dateEndEl, timeEndEl, daysEl, price = document?.querySelector('#price') ) => {

	const days = calculation(dateStartEl, timeStartEl, dateEndEl, timeEndEl);

	daysEl && (daysEl.value = days);
	calculatePrice(days, price);
}

const calculation = (dateStartEl, timeStartEl, dateEndEl, timeEndEl) => {
	let maxHour = online_media.parking_after_limit ?? '22',
		minHour = online_media.parking_before_limit ?? '02',
		diffDays = 0;

	if (dateStartEl.value !== '' && timeStartEl.value !== '' && dateEndEl.value !== '' && timeEndEl.value !== '') {
		const timeDeparture = formatTime(timeStartEl.value) + ':00';
		const timeDepartureLimit = maxHour + ":00:00";

		const timeArrival = formatTime(timeEndEl.value) + ':00';
		const timeArrivalStart = "00:00:00";
		const timeArrivalEnd = minHour + ":00:00";


		const initialDeparture = dateStartEl.value.split('-');
		const formattedDeparture = new Date([initialDeparture[2], initialDeparture[1], initialDeparture[0]].join('-'));

		const initialArrival = dateEndEl.value.split('-');
		const formattedArrival = new Date([initialArrival[2], initialArrival[1], initialArrival[0]].join('-'));

		const compareDeparture = dateCompareDeparture(timeDeparture, timeDepartureLimit);

		if (compareDeparture >= 0) formattedDeparture.setDate(formattedDeparture.getDate() + 1);


		const now = dateCompareArrival(timeArrival);
		const start = dateCompareArrival(timeArrivalStart);
		let end = dateCompareArrival(timeArrivalEnd);
		if (start > end) end += dateCompareArrival('24:00');

		if ((now >= start) && (now < end)) {
			formattedArrival.setDate(formattedArrival.getDate() - 1)
		}

		if (makeDate(dateStartEl.value) <= makeDate(dateEndEl.value)) {
			diffDays = calculate(formattedDeparture, formattedArrival);
		}
	}

	return diffDays;
}

const formatTime = (time) => {
	let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
	hours = hours < 10 ? `0${hours}` : `${hours}`;
	return `${hours}:${minutes}`;
}

const makeDate = (date) => {
	let dateArr = date.split('-');

	return dateArr[2] + dateArr[1] + dateArr[0];
}

const dateCompareDeparture = (time1, time2) => {
	const t1 = new Date();
	let parts = time1.split(":");
	t1.setHours(parts[0], parts[1], parts[2], 0);
	const t2 = new Date();
	parts = time2.split(":");
	t2.setHours(parts[0], parts[1], parts[2], 0);

	if (t1.getTime() > t2.getTime()) return 1;
	if (t1.getTime() < t2.getTime()) return -1;
	return 0;
}

const dateCompareArrival = (str) => {
	const time = str.split(':');
	return time[0] * 60 + time[1] * 1;
}
const calculate = (d1, d2) => {
	const oneDay = 24 * 60 * 60 * 1000;
	let diff = 0;
	if (d1 && d2) {
		diff = Math.round(Math.abs((d2.getTime() - d1.getTime()) / (oneDay))) + 1;
	}

	return diff;
}

export {
	calculateDays,
	calculation
};
