import tippy from 'tippy.js/dist/tippy.esm';

export class Tippy {
	init() {
		let tippyElements = document.querySelectorAll('[data-tippy]');

		tippyElements && tippyElements.forEach(tag => {
			tippy(`#${tag.id}`, {
				content: tag.dataset.tippy,
				trigger: 'click',
				allowHTML: true,
				animation: 'fade',
				theme: 'light',
				interactive: true,
				appendTo: 'parent',
				maxWidth: 300,
				moveTransition: 'transform 0.2s ease-out',
				arrow: false,
				placement: 'top',
				offset: [0, 20],
			});
		});

		return this;
	}
}
