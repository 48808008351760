const loadLateData = (output, date = '') => {
	fetch(online_media.root + 'load/late-schedule?date=' + date)
		.then(res => res.json())
		.then(result => {
			output.innerHTML = result;
		})
		.catch(err => {
			console.error(err);
		});
};

export {
	loadLateData
};
