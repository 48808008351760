import {addSchedule, completeNote} from "@modules/schedule";
import {ShowElement, HideElement, ToggleElement} from '@modules/utils';
import {ajaxLoginForm} from "@modules/ajaxLoginForm";
import {ajaxLogout} from "@modules/ajaxLogout";
import {endWork} from "@modules/endWork";
import {startWork} from "@modules/startWork";
import {checkWorkState} from "@modules/checkWorkState";
import Choices from "choices.js";
import {calculateDays} from "@modules/calculateDays";
import {Tippy} from "@modules/tippy";
import {checkArrivalData} from "@modules/checkArrivalData";
import Datepicker from 'vanillajs-datepicker/Datepicker';
import srLatin from 'vanillajs-datepicker/locales/sr-latn';
import "jquery-timepicker/jquery.timepicker";
import {downloadReport} from "./modules/downloadReport";
import {loadLateData} from "./modules/checkLateData";
import {downloadFile} from "./modules/utils";

Object.assign(Datepicker.locales, srLatin);

const {__} = wp.i18n;

window.addEventListener('load', () => {
	document.addEventListener('click', e => {
		if (!e.target.closest('.reservation-form button')) return;
		e.preventDefault();
		let form = e.target.closest('.reservation-form'), form_btn = e.target.closest('.reservation-form button');

		let valid = [], message = form.querySelector('.message'), inputs = form.querySelectorAll('input');

		inputs.length && inputs.forEach(item => {
			item.classList.remove('error');
			if (item.required && item.value === '') {
				item.classList.add('error');
				valid.push(false);
			}
		});

		if (!valid.includes(false)) {
			addSchedule(form, 'https://parkingnaaerodromu.rs/wp-json/add/schedule', form_btn);
		} else {
			message.innerHTML = 'Popunite obavezna polja';
		}
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('.check-schedule')) return;

		let closest_el = e.target.closest('.check-schedule'), closest_modal = document.querySelector('#checked-modal'),
			post_id = closest_el.dataset.post, parent_post = closest_el.dataset.main,
			options = closest_el.dataset.options, values = JSON.parse(options),
			actions = closest_modal?.querySelector('.actions'),
			paymentMethods = actions?.querySelectorAll('input'),
			elements = closest_modal?.querySelectorAll('.form-group');

		if (closest_el.classList.contains('only-view')) {
			closest_modal.classList.add('only-view');
		}

		if (values['hide_actions']) {
			actions.style.display = 'none';
		}

		let postId = closest_modal?.querySelector('[name="post_id"]'),
			parentPost = closest_modal?.querySelector('[name="parent_post"]');


		paymentMethods.length && paymentMethods.forEach(method => {
			if (values['payment_method'] === method.value) {
				method.checked = true;
			} else {
				method.checked = false;
			}
		});

		postId && (postId.value = post_id);
		parentPost && (parentPost.value = parent_post);

		elements.length && elements.forEach(element => {
			if (values[element.id] !== '' && values[element.id] !== undefined) {
				if (element.querySelector('input')) {
					element.querySelector('input').value = values[element.id];
				}

				if (element.querySelector('textarea')) {
					element.querySelector('textarea').value = values[element.id];
				}

				if (element.querySelector('span')) {
					element.querySelector('span').innerHTML = values[element.id];
				}
			}
		});

		const time = closest_modal?.querySelector('#time'),
			timeTo = closest_modal?.querySelector('#time_to');

		if (values['time'] !== '' && values['time'] !== undefined) {
			time.value = values['time'];
		}

		if (values['time_to'] !== '' && values['time_to'] !== undefined) {
			timeTo.value = values['time_to'];
		}
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('#checked-modal .complete-schedule [name="om_parking_payment_method"]')) return;
		e.preventDefault();

		let closest_el = e.target.closest('#checked-modal .complete-schedule [name="om_parking_payment_method"]'),
			note = e.target.closest('#checked-modal')?.querySelector('#om_parking_note'),
			comment = e.target.closest('#checked-modal')?.querySelector('#om_parking_note_after_checked'),
			user = e.target.closest('#checked-modal')?.querySelector('[name="om_parking_user"]'),
			loyalty = e.target.closest('#checked-modal')?.querySelector('[name="om_parking_loyalty_discount"]'),
			extraFee = e.target.closest('#checked-modal')?.querySelector('[name="om_parking_extra_price"]'),
			regTag = e.target.closest('#checked-modal')?.querySelector('[name="om_parking_reg_tag"]'),
			form = e.target.closest('#checked-modal .complete-schedule');

		completeNote(closest_el, note.value ?? '', comment.value ?? '', user.value ?? '', form, regTag.value ?? '', loyalty.value ?? 0, extraFee.value ?? 0);
	});

	document.addEventListener('changeDate', e => {
		if (!e.target.closest('#checked-modal .complete-schedule [name="om_parking_date_return"]')) return;

		const form = e.target.closest('#checked-modal .complete-schedule'),
			dateStart = form?.querySelector('[name="om_parking_date"]'),
			timeStart = form?.querySelector('#time'),
			dateEnd = form?.querySelector('[name="om_parking_date_return"]'),
			timeEnd = form?.querySelector('#time_to'),
			price = form?.querySelector('#price input');

		if (!dateStart.value || !timeStart.value || !dateEnd.value || !timeEnd.value) return;

		calculateDays(dateStart, timeStart, dateEnd, timeEnd, null, price);
	});

	document.addEventListener('changeDate', e => {
		if (!e.target.closest('input.calculate-days')) return;

		if (e.target.value === '') return;

		triggerCalculation();
	});

	document.addEventListener('input', e => {
		if (!e.target.closest('input.calculate-days')) return;

		if (e.target.value === '') return;

		triggerCalculation();
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('[data-open]')) return;
		ShowElement(e.target.closest('[data-open]'));
	});

	document.addEventListener('click', e => {
		const opened_modal = document.querySelector('.modal.show');
		if (e.target.closest('[data-close]')) {
			HideElement(e.target.closest('[data-close]'));
		} else if (e.target === opened_modal) {
			HideElement(opened_modal);
		}
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('[data-togle]')) return;
		ToggleElement(e.target.closest('[data-togle]'));
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('button#start-work')) return;
		e.preventDefault();

		startWork(e.target.closest('button#start-work'));
	});

	const form = document.querySelector('form#loginform');
	form && form.addEventListener('submit', e => {
		e.preventDefault();
		ajaxLoginForm(form);
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('button#logout')) return;
		e.preventDefault();

		ajaxLogout(e.target.closest('button#logout'));
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('button#end-work')) return;
		e.preventDefault();

		if (confirm('Da li ste sigurni da želite da završite smenu?') === true) {
			endWork(e.target.closest('button#end-work'));
		}
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('button[data-open="#state-work-modal"]')) return;
		e.preventDefault();

		const button = e.target.closest('button[data-open="#state-work-modal"]'),
			modal = document.querySelector('#state-work-modal'),
			modalBody = modal.querySelector('.modal__body');

		checkWorkState(button, modal, modalBody);
	});

	const selects = document?.querySelectorAll('.parking-container select');
	selects.length && selects.forEach(select => {
		new Choices(select, {
			placeholderValue: __('Pretraga po', 'om-parking'),
			searchPlaceholderValue: __('Pretraži po', 'om-parking'),
			oResultsText: __('Nema rezultata', 'om-parking'),
			noChoicesText: __('Ništa nije odabrano', 'om-parking'),
			searchEnabled: false
		});
	});

	const hasSearchParams = window.location.search, filters = document?.querySelector('.filters');
	if (hasSearchParams.includes('pretraga') && filters) {
		const top = filters.getBoundingClientRect().top + window.scrollY;
		window.scroll({
			top: top, behavior: 'smooth'
		});
	}

	const tippy = new Tippy();

	tippy.init();

	document.addEventListener('click', e => {
		if (!e.target.closest('#check_arrival_date_form > button')) return;
		e.preventDefault();

		const form = e.target.closest('#check_arrival_date_form'),
			date = form?.querySelector('[name="check_arrival_date"]'),
			modal = document?.querySelector('#check-arrival-modal');

		checkArrivalData(modal, modal?.querySelector('.results tbody'), date);
	});

	jQuery(document.body).on('om_parking_update_schedule', function (event, button, note, comment, form, regTag, dateFrom, dateTo, deletePost, checkedPost, loyalty, extraFee, days, price) {
		const formData = new FormData(form);
		formData.append('parent_post', button.dataset.main);
		formData.append('delete_post', deletePost);
		formData.append('checked_post', checkedPost);
		formData.append('date_from', dateFrom);
		formData.append('date_to', dateTo);
		formData.append('loyalty', loyalty);
		formData.append('extra_fee', extraFee);
		formData.append('days', days);
		formData.append('price', price);
		formData.append('reg_tag', regTag);

		fetch('https://parkingnaaerodromu.rs/wp-json/update/schedule', {
			'method': 'POST', 'body': formData
		}).catch(err => {
			console.error(err);
		});
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('#download-state')) return;

		fetch(online_media.root + 'download/state')
			.then(response => response.json())
			.then(data => {
				let file = data['file'];
				downloadFile(file);
			}).catch(err => console.error(err));
	});

	document.addEventListener('wpcf7submit', function (event) {
		setTimeout(function () {
			event.target?.classList?.remove('sent', 'failed', 'spam');
			event.target?.classList?.add('init');

			const output = event.target?.querySelector('.wpcf7-response-output');

			output && (output.innerHTML = '');
		}, 1000);

	}, false);

	const datepickerElements = document.querySelectorAll('.datepicker-input');
	datepickerElements && datepickerElements.forEach(input => {
		const datepicker = new Datepicker(input, {
			'format': 'dd-mm-yyyy',
			'language': 'sr-latn',
			'minDate': input.min || '',
			'maxDate': input.max || '',
			'weekStart': 1,
			'autohide': true,
		});
	});

	jQuery('.timepicker').timepicker({
		timeFormat: 'HH:mm',
		interval: 15,
		minTime: '00:00',
		maxTime: '23:45',
		startTime: '00:00',
		dynamic: false,
		dropdown: true,
		scrollbar: true,
		change: triggerCalculation
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('form.get-stats button')) return;
		e.preventDefault();

		downloadReport(e.target.closest('form.get-stats'));
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('form#check_late_date_form > button')) return;
		e.preventDefault();

		const form = e.target.closest('form#check_late_date_form'),
			date = form?.querySelector('[name="check_late_date"]'),
			output = document?.querySelector('#check-late-modal .results');

		loadLateData(output, date.value);
	});

	loadLateData(document?.querySelector('#check-late-modal .results'));
});

const triggerCalculation = () => {
	const dateStart = document?.querySelector('[name="om_parking_date"]'),
		timeStart = document?.querySelector('[name="om_parking_time"]'),
		dateEnd = document?.querySelector('[name="om_parking_date_return"]'),
		timeEnd = document?.querySelector('[name="om_parking_time_return"]'),
		days = document?.querySelector('[name="om_parking_days"]');

	if (!dateStart.value || !timeStart.value || !dateEnd.value || !timeEnd.value) return;

	calculateDays(dateStart, timeStart, dateEnd, timeEnd, days);
}
