const endWork = (button) => {
	const value = {
		action: 'online_media_end_work', nonce: online_media.nonce,
	};

	button && button.classList.add('loading');

	jQuery.post(online_media.ajaxUrl, value).done(data => {
		button && button.classList.remove('loading');

		if (data.data.success) {
			window.open(data.data.report, '_blank');
			setTimeout(() => {
				window.location.href = data.data.redirect;
			}, 100);
		} else {
			alert(data.data.message);
		}
	}).fail(err => console.error(err));
};

export {
	endWork
}
