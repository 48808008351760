const ajaxLoginForm = (form) => {
	const data = new FormData(form);
	const value = Object.fromEntries(data.entries());
	const errorDiv = form.querySelector('.error');

	Object.assign(value, {
		action: 'online_media_login_popup', nonce: online_media.nonce,
	});

	errorDiv && errorDiv.remove();

	jQuery.post(online_media.ajaxUrl, value).done(data => {
		if (data.data.success) {
			window.location.href = form?.querySelector('input[name="redirect_to"]')?.value;
		} else {
			form.insertAdjacentHTML('beforeend', `<div class="error">${data.data.message}</div>`);
		}
	}).fail(err => console.error(err));
}

export {ajaxLoginForm}
