const checkArrivalData = (element, output, date) => {
	const api = element?.querySelector("[name='check_arrival_data']");

	if (api) {
		fetch(api.value + '?date=' + date.value)
			.then(res => res.json())
			.then(result => {
				let rows = '';

				if(result.data) {

					result.data.forEach((item, index) => {
						index++;

						let phone = '';
						if(result.has_phone) {
							phone = `<br>${item.phone}`;
						}

						rows += `<tr>
									<td>${index}</td>
									<td>${item.name}${phone}</td>
									<td>${item.date}</td>
								</tr>`;
					});
				} else {
					rows = `<tr><td colspan="3">${result.message}</td></tr>`;
				}

				output.innerHTML = rows;
			})
			.catch(err => {
				console.error(err);
			});
	}
}

export {checkArrivalData};
