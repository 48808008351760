const checkWorkState = (button, modal, output) => {
	const startDate = modal?.querySelector('input[name="start_date"]');

	const data = {
		action: 'online_media_check_work_state', nonce: online_media.nonce,
		start_date: startDate?.value
	};

	output && (output.innerHTML = '');

	button && button.classList.add('loading');

	jQuery.post(online_media.ajaxUrl, data)
		.done(data => {
			if(data.data.layout && data.data.layout !== '') {
				output.innerHTML = data.data.layout + data.data.layout_2;
			} else {
				output.innerHTML = data.data.message;
			}
			button.classList.remove('loading');
		})
		.fail(err => console.error(err));
};

export {
	checkWorkState
}
