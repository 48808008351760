const calculatePrice = (days, priceEl) => {
	const prices = document?.querySelector('[name="prices"]')?.value;

	let currentPrice = 0;

	if(!prices) {
		return;
	}

	for (const price of JSON.parse(prices)) {
		if (price['day_min'] !== '') {
			const dayMin = parseInt(price['day_min']);
			const dayMax = price['day_max'] === '' ? Infinity : parseInt(price['day_max']);
			const total = parseInt(price['price']) || 0;

			if (days >= dayMin && days <= dayMax) {
				currentPrice = total;
				if (price['range'] !== '' && price['price_per_day'] !== '') {
					currentPrice = total + (parseInt(price['price_per_day']) * (days - parseInt(price['range'])));
				}

				if (price['price_per_day'] !== '' && price['range'] === '') {
					currentPrice = parseInt(price['price_per_day']) * days;
				}
				break;
			}
		}
	}

	priceEl && (priceEl.value = currentPrice);
}

export {
	calculatePrice
};
